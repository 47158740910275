const get_time_diff = (first, second) => {
  const min_diff = (second % 100) - (first % 100);
  const hr_diff = Math.floor(second / 100) - Math.floor(first / 100);
  return 60 * hr_diff + min_diff;
};

export const unav_to_av = (unavs) => {
  const unavs_by_day = new Array(7).fill().map((i) => []);
  const avs_by_day = new Array(7).fill().map((i) => []);
  for (const uv of unavs) {
    unavs_by_day[uv.day].push(uv);
  }

  for (const [idx, day] of unavs_by_day.entries()) {
    // Combine unavailabilities which are <= 30 mins apart
    day.sort((a, b) => a.start - b.start);
    for (let i = 1; i < day.length; i++) {
      if (get_time_diff(day[i - 1].end, day[i].start) <= 30) {
        day[i - 1].end = day[i].end;
        day.splice(i, 1);
        i--;
      }
    }

    const av = avs_by_day[idx];

    // Convert from unavailability to availability
    av.push({ start: 0, end: day[0]?.start ?? 2359 });

    for (let i = 1; i < day.length; i++) {
      av.push({ start: day[i - 1].end, end: day[i].start });
    }

    if (day.length) {
      av.push({ start: day[day.length - 1].end, end: 2359 });
    }
  }

  return avs_by_day;
};

export const format_time = (time) => {
  const hour = Math.floor(time / 100);
  const min = time % 100;
  if (hour === 0 && min === 0) return '12:00 AM';
  return `${hour > 12 ? hour - 12 : hour}:${min < 10 ? '0' + min : min} ${hour >= 12 ? 'P' : 'A'}M`;
};

export const get_status = (unav) => {
  const now = new Date();
  const time = now.getHours() * 100 + now.getMinutes();
  const weekday = now.getDay();

  const avs = unav_to_av(unav);
  let next = null;

  for (const [idx, av] of avs[weekday].entries()) {
    if (av.start <= time && av.end > time) {
      return { available: true, until: av.end };
    } else if (
      av.end <= time &&
      idx < avs[weekday].length - 1 &&
      avs[weekday][idx + 1].start > time
    ) {
      next = avs[weekday][idx + 1].start;
      break;
    }
  }

  return { available: false, until: next ?? 2359 };
};

export const DAY_NAMES = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];
