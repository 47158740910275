import Homepage from './components/Homepage/Homepage';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import RoomPage from './components/RoomPage/RoomPage';
import { loader as roomLoader } from './components/RoomPage/RoomPage';
import BuildingPage from './components/BuildingPage/BuildingPage';
import { loader as buildingLoader } from './components/BuildingPage/BuildingPage';

function App() {
  const router = createBrowserRouter([
    { path: '/', element: <Homepage /> },
    { path: 'room/:roomId', element: <RoomPage />, loader: roomLoader },
    { path: 'building/:buildingId', element: <BuildingPage />, loader: buildingLoader }
  ]);

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
