import { useLoaderData } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { API_BASE_URL } from '../../util/url';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import RoomCard from '../RoomCard/RoomCard';

export async function loader({ params }) {
  return { buildingId: params.buildingId };
}

const BuildingPage = () => {
  const [building, setBuilding] = useState(null);
  const { buildingId } = useLoaderData();

  useEffect(() => {
    if (!buildingId) return;
    fetch(`${API_BASE_URL}/building/${buildingId}?include_availability=true`)
      .then((res) => res.json())
      .then((body) => {
        body.building.Rooms.sort((a, b) => a.name.localeCompare(b.name));
        setBuilding(body.building);
      });
  }, [buildingId]);

  return (
    <Container style={{ marginBottom: '4rem' }}>
      <h1 style={{ marginBottom: '3rem', marginTop: '1rem' }}>{building?.name ?? ''}</h1>
      <Row xs={1} sm={3} md={5} className="g-4">
        {building?.Rooms &&
          building.Rooms.map((room) => (
            <Col key={room.id}>
              <RoomCard room={room} />
            </Col>
          ))}
      </Row>
    </Container>
  );
};

export default BuildingPage;
