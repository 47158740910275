import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { get_status, format_time } from '../../util/times';

const RoomCard = ({ room }) => {
  const [status, setStatus] = useState(null);

  useEffect(() => {
    setStatus(get_status(room.Unavailabilities));
  }, [room.Unavailabilities]);

  return (
    <Card style={{ textAlign: 'left' }}>
      <Card.Header>
        {status && (
          <Badge bg={status.available ? 'success' : 'danger'}>
            {status.available ? 'Available' : 'In Use'}
          </Badge>
        )}
      </Card.Header>
      <Card.Body>
        <Card.Title>{room.name}</Card.Title>

        <Link to={`/room/${room.id}`}>
          <Card.Text>
            {status
              ? `Available ${status.available ? 'until' : 'at'} ${format_time(status.until)}`
              : 'Check Availability'}
          </Card.Text>
        </Link>
      </Card.Body>
    </Card>
  );
};

export default RoomCard;
