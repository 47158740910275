import BuildingList from '../BuildingList/BuildingList';
import Header from '../Header/Header';
import Dropdown from 'react-bootstrap/Dropdown';
import { useEffect, useState } from 'react';
import { API_BASE_URL } from '../../util/url';
// import styles from './Homepage.module.scss';

const Homepage = () => {
  const [regions, setRegions] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [buildings, setBuildings] = useState([]);

  useEffect(() => {
    fetch(`${API_BASE_URL}/regions`)
      .then((res) => res.json())
      .then((body) => {
        setRegions(body.regions.sort((a, b) => a.name.localeCompare(b.name)));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (!selectedRegion) return;
    fetch(`${API_BASE_URL}/buildings?region=${selectedRegion.id}`)
      .then((res) => res.json())
      .then((body) => {
        setBuildings(body.buildings.sort((a, b) => a.name.localeCompare(b.name)));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedRegion]);

  return (
    <>
      <Header />
      <Dropdown>
        <Dropdown.Toggle>{selectedRegion?.name ?? 'Select a Region'}</Dropdown.Toggle>
        <Dropdown.Menu>
          {regions.map((region) => (
            <Dropdown.Item key={region.id} onClick={() => setSelectedRegion(region)}>
              {region.name}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      <br />
      <BuildingList buildings={buildings} />
    </>
  );
};

export default Homepage;
