import { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import { useLoaderData } from 'react-router-dom';
import { API_BASE_URL } from '../../util/url';
import { unav_to_av, DAY_NAMES, format_time } from '../../util/times';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';

export async function loader({ params }) {
  return { roomId: params.roomId };
}

const RoomPage = () => {
  const [room, setRoom] = useState(null);
  const [availability, setAvailability] = useState(null);
  const { roomId } = useLoaderData();

  useEffect(() => {
    if (!roomId) return;
    fetch(`${API_BASE_URL}/room/${roomId}`)
      .then((res) => res.json())
      .then((body) => {
        setRoom(body.room);
        setAvailability(unav_to_av(body.room.Unavailabilities));
      });
  }, [roomId]);

  return (
    <Container>
      <h1 style={{ marginBottom: '3rem', marginTop: '1rem' }}>{room?.name ?? ''}</h1>
      <Accordion>
        {availability &&
          availability.map((day, idx) => (
            <Accordion.Item eventKey={idx}>
              <Accordion.Header>{DAY_NAMES[idx]}</Accordion.Header>
              <Accordion.Body>
                {day.map((av) => (
                  <Card>
                    <Card.Body>
                      <Card.Text>
                        {format_time(av.start)} - {format_time(av.end)}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                ))}
              </Accordion.Body>
            </Accordion.Item>
          ))}
      </Accordion>
    </Container>
  );
};

export default RoomPage;
