import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';

const BuildingCard = ({ name, region, id }) => {
  return (
    <Card>
      <Card.Img
        src={require(`../../building-images/${region}/${name}.jpg`)}
        style={{ objectFit: 'cover' }}
        height={300}
      />
      <Card.Body>
        <Card.Title>{name}</Card.Title>
        <Card.Text>{region} Campus</Card.Text>
        <Link to={`/building/${id}`}>
          <Button variant="primary">View Rooms</Button>
        </Link>
      </Card.Body>
    </Card>
  );
};

export default BuildingCard;
