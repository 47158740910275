import BuildingCard from '../BuildingCard/BuildingCard';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import styles from './BuildingList.module.scss';

const BuildingList = ({ buildings }) => {
  return (
    <Container fluid={true} className={styles.buildingList}>
      <Row xs={1} sm={2} md={3} className="g-4">
        {buildings.map((bldg) => (
          <Col key={bldg.id}>
            <BuildingCard id={bldg.id} name={bldg.name} region={bldg.Region.name} />
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default BuildingList;
